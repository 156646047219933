@import '../tokens/breakpoints';

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: calc(var(--spacing) / 2);

  @include respond-medium-up {
    grid-template-columns: 1fr 1fr;
  }
}

.grid__item {
  position: relative;
  overflow: hidden;
  background: var(--color-content);
  border-radius: var(--radius-small);
  text-decoration: none;
  box-shadow: 0 0 1px 0 var(--color-shadow) inset;
  mask-border-source: url('../assets/img/mask.png');
  mask-border-slice: 25 fill;
  mask-border-width: 1rem;
  mask-border-repeat: repeat;

  &.grid__item--with-visual {
    height: 0;
    padding-top: 60%;

    @include respond-medium-up {
      padding-top: 70%;
    }

    @include respond-large-up {
      padding-top: 80%;
    }

    .grid__content {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        to bottom,
        transparent,
        var(--color-elevation),
        var(--color-elevation)
      );
    }
  }
}

.grid__image {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  object-fit: cover;
  width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

.grid__content {
  position: relative;
  z-index: 2;
  margin-top: auto;
  padding: var(--spacing);
  text-shadow: 0 0 0.5rem var(--color-elevation),
    0 0 0.5rem var(--color-elevation);

  @include respond-medium-up {
    padding: calc(var(--spacing) / 1.5);
  }
}

.grid__title {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: var(--color-on-elevation);
  overflow: hidden;
}
