@import '../tokens/breakpoints';

.skeleton {
  display: grid;
  grid-gap: var(--spacing);
  grid-template-areas:
    'header'
    'main'
    'nav'
    'footer';

  @include respond-large-up {
    grid-template-areas:
      'header main'
      'nav main'
      'nav footer';
    grid-template-columns: 250px 1fr;
  }
}

.skeleton__header {
  grid-area: header;
  padding: 0 1rem;
}

.skeleton__nav {
  grid-area: nav;
}

.skeleton__main {
  grid-area: main;
}

.skeleton__footer {
  grid-area: footer;
  color: var(--color-text-passive);
}
