@use 'sass:math';

@import '../tokens';

* {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  -webkit-text-size-adjust: 100%;

  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  padding: var(--spacing);
  background: var(--color-background);
  color: var(--color-text);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
  line-height: var(--line-height);
}

p {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}
