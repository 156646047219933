@import './breakpoints';

@mixin spacing-tokens {
  --spacing: 20px;

  @include respond-medium-up {
    --spacing: 30px;
  }

  @include respond-large-up {
    --spacing: 40px;
  }
}
