@keyframes spinner-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.spinner {
  margin: 150px auto;
  text-align: center;
}

.spinner__item {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin: 0 0.25rem;
  background: var(--color-text);
  border-radius: 1rem;
  animation: spinner-bounce 1.4s infinite ease-in-out both;

  &.spinner__item--1 {
    animation-delay: -0.32s;
  }

  &.spinner__item--2 {
    animation-delay: -0.16s;
  }
}
